<template>
  <div>
    <layout-vertical>

      <template>
        <div>
          <app-profile-alert
            v-if="isVerifyEmailLater && status && !isEmailVerified"
            class="mb-2"
          >
            <template #title>
              {{ $t('home.textToHelpYouRun') }} <span
                class="link text-success"
                @click="handleRequestVerifyEmail"
              >
                {{ $t('common.linkClickHere') }}
              </span> {{ $t('home.textToVerifyYourEmail') }}
            </template>
          </app-profile-alert>
          <div v-if="showStepGuide">
            <div v-if="isExclusivePartner">
              <warning-notification
                class="notification-refund-policy"
                :link-detail="linkDetail"
              >
                <template #title>
                  <div class="title-notification">
                    <div class="ic-docs">
                      <feather-icon
                        icon="FileTextIcon"
                        size="24"
                      />
                    </div>
                    <h3 class="m-0">
                      [AdReach] Refund Policy
                    </h3>
                    <div class="ic-notification">
                      <feather-icon
                        icon="ArrowUpRightIcon"
                        size="21"
                      />
                    </div>
                  </div>
                </template>

                <template #content>
                  <div>
                    <p class="m-0">
                      Please review our refund policy carefully to understand your rights and obligations when requesting a refund.
                    </p>
                  </div>
                </template>
              </warning-notification>
            </div>

            <warning-notification v-if="isSMBAgencyPlatform">
              <template #title>
                <div class="d-flex">
                  <img
                    class="alert-img mr-50 mb-1"
                    alt="alert"
                    width="26"
                    :src="require('@/assets/images/common/ic-speaker.png')"
                  >
                  <p class="font-weight-bold">
                    {{ $t('home.warningNotificationCashbackSBH.titleNotification', {platform: `${ platformName }`}) }}
                  </p>
                </div>
              </template>
              <template #content>
                <p class="mb-0">
                  {{ $t('home.warningNotificationCashbackSBH.content2') }}
                </p>
                <p class="mb-0">
                  {{ $t('home.warningNotificationCashbackSBH.content3') }}
                </p>
                <p>
                  {{ $t('home.warningNotificationCashbackSBH.content4') }}
                </p>
                <div class="d-flex align-items-center">
                  <span>{{ $t('home.warningNotificationCashbackSBH.thankyou') }}
                    <user-hint
                      :hide-icon="true"
                      :hide-quote="true"
                    />
                  </span>
                </div>
              </template>
            </warning-notification>

            <b-row>
              <b-col
                md="5"
                lg="3"
                class="d-none d-md-block"
              >
                <setup-guide-control />
                <list-event-slide
                  v-if="false"
                  class="mt-2"
                />
              </b-col>
              <b-col
                cols="12"
                class="d-md-none"
              >
                <setup-guide-mobile />
              </b-col>
              <b-col
                md="7"
                :lg="isTabRefundHistory ? '9' : '6'"
                cols="12"
                class="step-view"
              >
                <user-guide v-if="showUserGuide && ($device.mobile || $device.ipad)" />

                <router-view />

                <list-event-slide
                  v-if="false"
                  class="mt-2 d-md-none"
                />
              </b-col>

              <b-col
                v-if="!isTabRefundHistory && !$device.mobile && !$device.ipad"
                lg="3"
                class="position-sticky user-guide-container"
              >
                <user-guide v-if="showUserGuide" />
              </b-col>
            </b-row>
          </div>
          <div v-else>
            <router-view />
          </div>
        </div>
      </template>
      <template #navbar="{ toggleVerticalMenuActive }">
        <navbar
          v-if="isAuthenticated"
          :toggle-vertical-menu-active="toggleVerticalMenuActive"
        />
        <div v-else />
      </template>

    <!--    <app-customizer-->
    <!--      v-if="showCustomizer"-->
    <!--      slot="customizer"-->
    <!--    />-->
    </layout-vertical>
    <adreach-survey-modal
      ref="open-modal-adreach-survey"
      :user-info="userInfo"
    />
    <send-email-success-modal ref="verifyEmailRef" />
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
// import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
import { $themeConfig } from '@themeConfig'
import SetupGuideMobile from '@/components/setup-guide-component/SetupGuideMobile.vue'
import SetupGuideControl from '@/components/setup-guide-component/SetupGuideControl.vue'
import ListEventSlide from '@/views/home/components/ListEventSlide.vue'
import stepGuideMixin from '@/mixins/stepGuideMixin'
import AppProfileAlert from '@core/components/app-alert-top-bar/AppProfileAlert.vue'
import { createNamespacedHelpers } from 'vuex'
import { toastification } from '@core/mixins/toast'
import SendEmailSuccessModal from '@core/components/app-alert-top-bar/SendEmailSuccessModal.vue'
import AdreachSurveyModal from '@/views/home/components/AdreachSurveyModal.vue'
import _get from 'lodash/get'
import envMixin from '@/mixins/envMixin'
import UserGuide from '@/components/UserGuide.vue'
import WarningNotification from '@/views/home/components/WarningNotification.vue'
import UserHint from '@/components/UserHint.vue'
import Navbar from '../components/Navbar.vue'

const { mapGetters, mapActions } = createNamespacedHelpers('auth')
export default {
  components: {
    UserHint,
    SendEmailSuccessModal,
    AppProfileAlert,
    // AppCustomizer,
    LayoutVertical,
    Navbar,
    SetupGuideControl,
    SetupGuideMobile,
    ListEventSlide,
    AdreachSurveyModal,
    UserGuide,

    BRow,
    BCol,
    WarningNotification,
  },

  mixins: [stepGuideMixin, toastification, envMixin],

  data() {
    return {
      showCustomizer: $themeConfig.layout.customizer,
      isTabRefundHistory: false,
      linkDetail: 'https://ecomdycom.larksuite.com/docx/NA9LdCbBNo2IQFxXCkluRequsGe',
    }
  },

  computed: {
    ...mapGetters(['user', 'status', 'error', 'loading', 'message', 'isAuthenticated']),
    userInfo() {
      return _get(this.user, ['data'])
    },

    showStepGuide() {
      const listPageNoNeedStepGuide = [
        'profile',
        'billing',
        'pricing',
        'referral-partner-info',
        'faq',
        'referral-introduce',
        'referral',
        'reactive-stripe',
        'affiliate-program',
      ]

      const currentPage = this.$route?.name
      const partnerPage = 'partner-management'
      const partnerInfoPage = 'partner-info-url'
      const memberPartnerPage = 'member'
      const pixelPageNames = [
        'pixel-management',
        'pixel-create',
        'pixel-detail',
      ]
      if (pixelPageNames?.includes(currentPage)) {
        return false
      }

      return this.hasStepGuide
        && !listPageNoNeedStepGuide?.includes(currentPage)
        && !currentPage?.includes(partnerPage)
        && !currentPage?.includes(memberPartnerPage)
        && !currentPage?.includes(partnerInfoPage)
    },
    isEmailVerified() {
      return this.user.data?.verifyEmailAt
    },
  },

  watch: {
    generalConfigs: {
      handler() {
        if (this.userInfo && this.userInfo?.onboardingStep === 2 && localStorage.getItem('isDoneSurvey') !== 'true' && this.isExclusivePartner) {
          this.openAdreachSurveyModal()
        }
      },
      deep: true,
      immediate: true,
    },

    $route: {
      handler(route) {
        if (route?.query?.tabActive === 'refund_history') {
          this.isTabRefundHistory = true
        } else {
          this.isTabRefundHistory = false
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    ...mapActions(['requestVerifyEmail']),
    async handleRequestVerifyEmail() {
      if (this.loading) return

      await this.requestVerifyEmail()

      if (this.status) {
        this.$refs.verifyEmailRef.showModal()
      } else {
        this.toastFailure(this.message)
      }
    },

    openAdreachSurveyModal() {
      this.$refs['open-modal-adreach-survey'].showModal()
    },
  },
}
</script>

<style lang="scss" scoped>
  .link{
    text-decoration: underline;
    cursor: pointer;
  }

  .user-guide-container {
    height: max-content;
    top: 24px;
  }

  .notification-refund-policy {
    cursor: pointer;
    background: rgba(38, 103, 255, 0.1) !important;

    .title-notification {
      margin-bottom: .5rem;

      display: flex;
      align-items: center;

      .ic-docs {
        margin-right: 5px;
      }

      .ic-notification {
        margin-left: 6px;
        color: #2667ff;
      }
    }
  }
</style>
